/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { parseTimeInTZ } from '../../Helpers/momentHelpers'
import { usePagination } from '../../Helpers/usePagination'
import ActionBarComponent from './ActionBar'
import BlockAuthHeader from './BlockHeader'
import BlockList from './BlockList'
import BlockTable from './BlockTable'
import {
  loadingToast,
  successToast,
  errorToast,
  formatToIds,
  SHIFT_BLOCK,
  SHIFT_BLOCKS,
  SHIFT_BLOCK_PUBLISH,
  SHIFT_DETAILS,
  SHIFT_BULT_DELETE,
} from './helpers/BlockBookingsHelpers'
import BlockFilters from './helpers/BlockFilters'
import useShiftSelector from './hooks/useShiftSelector'
import CreateBlockModal from './Modals/CreateBlockModal'
import DeleteBlockModal from './Modals/DeleteBlockModal'
import RecallFromAgencyModal from './Modals/RecallFromAgencyModal'
import SendBlockToAgencyModal from './Modals/SendBlockToAgencyModal'
import ShiftsModal from './Modals/ShiftsModal'
import ViewBlockModal from './Modals/ViewBlockModal'

function BlockBookings({
  agencyWorkers,
  bankWorkers,
  modOrganisation,
  organisation,
  user,
  canCancelBooking,
  canManageProposals,
  canShortlistProposals,
  timesheets,
  usesDeContracts,
}) {
  /* HOOKS **************************************** */
  const {
    deselectAllShifts,
    selectAllShifts,
    selectedShiftId,
    selectedShifts,
    isShiftSelected,
    toggleShiftSelection,
    actionBarVisible,
    toggleActionBar,
    activeWeek,
    setActiveWeek,
    selectWeeklyShifts,
  } = useShiftSelector()

  const [inputs, setInputs] = useState({
    blockId: null,
    blocksPage: 1,
    calendarDetails: null,
    calendarArray: [],
    editShift: false,
    modOrganisation,
    organisation,
    rowsSearched: 25,
    selectedBlock: false,
    showFilters: false,
    showClearFilters: false,
    showCreateModal: false,
    showBlockModal: false,
    user,
    //
    activeTimesheet: false,
    amendedTimesheet: false,
  })

  const { calendarDetails, blocksPage } = inputs
  const [pagination, setPagination, resetPagination] = usePagination(inputs.rowsSearched)

  /* FILTERS **************************************** */

  const [filtersInputs, setFiltersInputs] = useState({
    blockIds: null,
    siteIds: [],
    departmentIds: [],
    statuses: [],
    gradeIds: [],
    startDateFrom: null,
    startDateTo: null,
    endDateFrom: new Date(),
    endDateTo: null,
    agencyRegistrationId: null,
    organisationRegistrationId: null,
  })

  /* LOCATION **************************************** */
  const location = useLocation()

  // Function to update state and showFilters based on URL params
  const handleURLParams = () => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search)
      const filtersParam = searchParams.get('filters')

      if (filtersParam === 'proposal') {
        setInputs((prevInputs) => ({ ...prevInputs, showFilters: true }))
        setFiltersInputs({
          ...filtersInputs,
          statuses: [{ label: 'Proposals', value: 'PROPOSALS' }],
          endDateFrom: null,
        })
      }
    }, 1000)
  }

  // Run handleURLParams whenever the 'location' object changes
  useEffect(() => {
    handleURLParams()
  }, [location])

  /* MODALS ****************************************** */

  const [shiftModalDate, setShiftModalDate] = useState()
  const [shiftDetails, setShiftDetails] = useState()

  const [blockToDelete, setBlockToDelete] = useState()
  const [sendBlockToAgencyOpen, setSendBlockToAgencyModal] = useState(false)
  const [recallBlockFromAgencyOpen, setRecallBlockFromAgencyModal] = useState(false)

  /* GQL *********************************** */

  const [publishMutation] = useMutation(SHIFT_BLOCK_PUBLISH)
  const [deleteMutation] = useMutation(SHIFT_BULT_DELETE)

  const { data, loading, error, refetch, networkStatus } = useQuery(SHIFT_BLOCKS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...pagination,
      // shiftBlockIds: [7896],
      shiftBlockIds: filtersInputs.blockIds,
      departmentIds: [...formatToIds(filtersInputs.departmentIds)],
      siteIds: [...formatToIds(filtersInputs.siteIds)],
      gradeIds: [...formatToIds(filtersInputs.gradeIds)],
      statuses: inputs.activeTimesheet
        ? ['BOOKED']
        : filtersInputs.statuses.length > 0
        ? filtersInputs.statuses.map((cc) => cc.value)
        : null,
      startDateFrom: filtersInputs.startDateFrom,
      startDateTo: filtersInputs.startDateTo,
      endDateFrom: filtersInputs.endDateFrom,
      endDateTo: filtersInputs.endDateTo,
      agencyRegistrationId: filtersInputs.agencyRegistrationId?.value ?? null,
      organisationRegistrationId: filtersInputs.organisationRegistrationId?.value ?? null,
      activeTimesheet: inputs.activeTimesheet,
      amendedTimesheet: inputs.amendedTimesheet,
    },
  })

  useQuery(SHIFT_BLOCK, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.shiftBlocks.nodes) {
        setInputs({ ...inputs, blockId: null, calendarDetails: res.shiftBlocks.nodes[0] })
      }
    },
    skip: !inputs.blockId,
    variables: {
      shiftBlockIds: [parseInt(inputs.blockId, 10)],
    },
  })

  const { data: details } = useQuery(SHIFT_DETAILS, {
    skip: !data || !organisation.id,
    variables: {
      id: Number(organisation.id),
    },
  })

  /* CREATE CALENDAR BLOCK ******************************* */

  const resetFilters = () => {
    resetPagination()

    setInputs({ ...inputs, showClearFilters: false, showFilters: false })

    setFiltersInputs(() => ({
      blockIds: [],
      siteIds: [],
      departmentIds: [],
      statuses: [],
      gradeIds: [],
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: new Date(),
      agencyRegistrationId: [],
      organisationRegistrationId: [],
    }))
  }

  const createBlockFunction = (block) => {
    setInputs({
      ...inputs,
      blocksPage: 2,
      blockId: block.id,
      showBlockModal: false,
      selectedBlock: false,
      showFilters: false,
    })
  }

  useEffect(() => {
    if (calendarDetails !== null) {
      let startWeekOfFirstShift = parseTimeInTZ(calendarDetails.startDate).startOf('isoWeek').format('YYYY-MM-DD')
      const startWeekOfLastShift = parseTimeInTZ(calendarDetails.endDate).startOf('isoWeek').format('YYYY-MM-DD')
      const weeksArray = []

      while (startWeekOfFirstShift <= startWeekOfLastShift) {
        const days = []
        let totalHours = 0

        /*
            VALIDATION CODE ******************************

            - We will need to loop through shifts and cumulatively add the hours into correct weeks
            - If a shift is on a sunday & spans multiple weeks we will need to exclude hours in the following week
            - We also need to account for 'breaks' and where they fit in.
        */

        calendarDetails.shifts.map((shift) => {
          const proposedStartTime = parseTimeInTZ(shift.proposedStartTime).startOf('isoWeek').format('YYYY-MM-DD')
          const proposedEndTime = parseTimeInTZ(shift.proposedEndTime).startOf('isoWeek').format('YYYY-MM-DD')

          /*  1. SUNDAY ROW - Starting week of the shift startTime === start of the week */
          if (proposedStartTime === startWeekOfFirstShift) {
            /* If shift start/endTime falls in the same week, continue as normal */
            if (proposedEndTime === startWeekOfFirstShift) {
              totalHours += shift.totalHours
              return
            }

            /* If shift start/endTime falls in the different weeks, we exlude Monday hours */

            const breaksGap = calendarDetails.grade.breakRepeatMinutes
            const endy = parseTimeInTZ(shift.proposedEndTime)
            const overTimeHours = parseTimeInTZ(endy).diff(
              parseTimeInTZ(startWeekOfFirstShift).add(1, 'week'),
              'minutes'
            )

            /* We need to factor in breakRepeatMinutes, if startTime + breakRepeatMinutes is on Sunday, we add breaks on Sunday, else Monday */
            if (
              parseTimeInTZ(shift.proposedStartTime).add(breaksGap, 'minutes').format('YYYY-MM-DD') ===
              parseTimeInTZ(shift.proposedStartTime).format('YYYY-MM-DD')
            ) {
              totalHours += (shift.totalHours * 60 - overTimeHours) / 60
              return
            }
            totalHours += 24 - parseTimeInTZ(shift.proposedStartTime).hours()
          }

          /*  2. MONDAY ROW - Starting week of the shift EndTime === start of the week  && the startTime of shift === the previous week */

          if (proposedEndTime === startWeekOfFirstShift && proposedStartTime !== startWeekOfFirstShift) {
            /* Calculate only Monday hours */
            const overTimeHours = parseTimeInTZ(shift.proposedEndTime).diff(
              parseTimeInTZ(startWeekOfFirstShift),
              'minutes'
            )
            const breaksGap = calendarDetails.grade.breakRepeatMinutes

            /* If shift StartTime + breaksGap === Sunday continue as normal, else Mondays hours = Total - Sunday hours. */

            if (
              parseTimeInTZ(shift.proposedStartTime).add(breaksGap, 'minutes').format('YYYY-MM-DD') ===
              parseTimeInTZ(shift.proposedStartTime).format('YYYY-MM-DD')
            ) {
              totalHours += overTimeHours / 60
              return
            }
            totalHours += shift.totalHours - (24 - parseTimeInTZ(shift.proposedStartTime).hours())
          }
        })

        // End ********************************************

        for (let daysSince = 0; daysSince < 7; daysSince += 1) {
          days.push({
            date: parseTimeInTZ(startWeekOfFirstShift).add(daysSince, 'days').format('YYYY-MM-DD'),
            shifts: calendarDetails.shifts.filter(
              (shift) =>
                parseTimeInTZ(shift.proposedStartTime).format('YYYY-MM-DD') ===
                  parseTimeInTZ(startWeekOfFirstShift).add(daysSince, 'days').format('YYYY-MM-DD') && shift
            ),
          })
        }
        weeksArray.push({
          days,
          startWeekOfFirstShift,
          totalHours,
        })
        startWeekOfFirstShift = parseTimeInTZ(startWeekOfFirstShift).add(7, 'day').format('YYYY-MM-DD')
      }

      setInputs({ ...inputs, calendarArray: weeksArray })
    }
  }, [calendarDetails])

  /* MUTATION FUNCTIONS ************************************* */

  const addShift = (date) => {
    setInputs({ ...inputs, editShift: false })
    setShiftModalDate(date)
    deselectAllShifts()
  }

  useEffect(() => {
    if (selectedShiftId) {
      const [newShiftDetails] = calendarDetails.shifts.filter((t) => t.id === selectedShiftId)
      setShiftDetails(newShiftDetails)
      return
    }

    setShiftDetails()
  }, [calendarDetails, selectedShiftId])

  const deleteShifts = () => {
    toast.loading('Loading', loadingToast)

    deleteMutation({
      variables: {
        shiftIds: selectedShifts,
      },
      update(cache, result) {
        const errors = result?.data.shiftBulkDelete?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: errors[0].message })
          return
        }

        const newObject = {
          nodes: [
            {
              ...calendarDetails,
              shifts: calendarDetails.shifts.filter((t) => !selectedShifts.includes(t.id)),
              shiftIds: calendarDetails.shiftIds.filter((t) => !selectedShifts.includes(t)),
            },
          ],
        }

        toast.update(2, { ...successToast, render: 'Shift Deleted' })
        setInputs({ ...inputs, calendarDetails: newObject.nodes[0] })
        deselectAllShifts()
      },
    }).catch(() => {
      toast.update(2, { ...errorToast, render: 'Shift could not be deleted' })
    })
  }

  const publishBlock = (id) => {
    toast.loading('Loading', loadingToast)

    publishMutation({
      variables: {
        shiftBlockId: Number(id),
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBlockPublish?.errors

        if (errors.length > 0) {
          const permissionError = errors.find((err) => {
            return err.message === 'not allowed to publish? this ShiftBlock'
          })

          const shiftsMissingError = errors.find((err) => {
            return err.message === 'Block must contain shifts to be published'
          })

          if (shiftsMissingError) {
            toast.update(2, { ...errorToast, render: 'Block must contain shifts to be published' })
          } else if (permissionError) {
            toast.update(2, {
              ...successToast,
              render: 'You do not have permission to publish the block, please contact an Admin',
            })
          } else {
            toast.update(2, errorToast)
          }
          return
        }

        setInputs({ ...inputs, blocksPage: 1, showCreateModal: false, showClearFilters: true })
        setFiltersInputs((oldState) => ({ ...oldState, blockIds: [Number(id)], statuses: [] }))

        toast.update(2, { ...successToast, render: 'Block Published' })
      })
      .catch(() => {
        toast.update(2, errorToast)
      })
  }

  const saveChanges = () => {
    toast.success('Changes Saved', { hideProgressBar: true, autoClose: 1500, position: 'top-right' })

    setFiltersInputs((oldState) => ({
      ...oldState,
      blockIds: [],
      siteIds: [],
      departmentIds: [],
      statuses: [],
      gradeIds: [],
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: new Date(),
      endDateTo: null,
      agencyRegistrationId: null,
      organisationRegistrationId: null,
    }))
    setInputs({ ...inputs, blocksPage: 1, showCreateModal: false, showClearFilters: false })
  }

  /* FILTER HANDLERS *************************************** */

  const handleFilter = (event, name) => {
    setFiltersInputs((oldState) => ({
      ...oldState,
      endDateFrom: null,
      [name]: event ?? [],
    }))

    setInputs({ ...inputs, showClearFilters: true })
  }

  /* FUNCTION  **************************** */

  useEffect(() => {
    if (blocksPage === 1) {
      deselectAllShifts()
      setShiftDetails()
      setInputs({ ...inputs, blockId: null, calendarArray: [], calendarDetails: null })
    }
  }, [blocksPage])

  const blockListTitle = (count) => {
    let matchText = 'Matches'
    if (count === 1) {
      matchText = 'Match'
    }
    return [count, matchText].join(' ')
  }

  const blockTableTitle = (name) => name || '-'

  const pageTitle = () => {
    if (blocksPage === 1) return blockListTitle(data?.shiftBlocks.totalCount)
    return blockTableTitle(calendarDetails?.name)
  }

  const subTitle = () => {
    if (blocksPage === 1) {
      return 'Blocks Found'
    }

    if (typeof calendarDetails === 'undefined' || calendarDetails === null) {
      return null
    }

    const { id, grade, modApprovalBudgetValue, staffGroup } = calendarDetails
    const subTitleParts = [`B${id}`, staffGroup.label, '-', grade.label]

    if (modApprovalBudgetValue) {
      subTitleParts.push('-', modApprovalBudgetValue, 'Weekly Approved Hours')
    }

    return subTitleParts.join(' ')
  }

  const pageInfo = data?.shiftBlocks?.pageInfo ?? ''

  return (
    <>
      <BlockHeader open={inputs.showFilters}>
        <BlockAuthHeader refetch={refetch} subtitle={subTitle()} title={pageTitle()} user={user} />

        <FilterContainer open={inputs.showFilters}>
          <BlockFilters
            agencyWorkers={agencyWorkers}
            bankWorkers={bankWorkers}
            details={details}
            filtersInputs={filtersInputs}
            handleFilter={handleFilter}
            resetPagination={resetPagination}
          />
        </FilterContainer>
      </BlockHeader>
      {blocksPage === 1 && (
        <BlockList
          blockData={data}
          error={!!error}
          handleChange={(e) => {
            if (e === '') {
              setFiltersInputs((oldState) => ({ ...oldState, blockIds: [] }))
              return
            }

            setFiltersInputs((oldState) => ({
              ...oldState,
              endDateFrom: null,
              blockIds: e.split(',').map((i) => Number(i)),
            }))
            setInputs({ ...inputs, showClearFilters: true })
          }}
          handleCompletedBlocks={() => {
            const choise = !!filtersInputs.endDateFrom

            setFiltersInputs((oldState) => ({
              ...oldState,
              endDateFrom: choise ? null : new Date(),
            }))
          }}
          inputs={inputs}
          loading={(loading && !data) || networkStatus === 2}
          pageInfo={pageInfo}
          pagination={pagination}
          resetFilters={resetFilters}
          selectBlockHandler={(e) => {
            setInputs({ ...inputs, selectedBlock: e })
            toggleActionBar(e)
          }}
          setFiltersOpen={() => setInputs({ ...inputs, showFilters: !inputs.showFilters })}
          setPagination={setPagination}
          setRowsSearched={(e) => {
            setInputs({ ...inputs, rowsSearched: e })
            setPagination((s) => {
              window.history.replaceState(null, null, s.url)
              return {
                ...s,
                after: undefined,
                before: undefined,
                first: e,
                items: e,
              }
            })
          }}
          showBlockModal={(e) => setInputs({ ...inputs, showBlockModal: e })}
          showCompletedBlocks={!!filtersInputs.endDateFrom}
          showCreateModal={() => setInputs({ ...inputs, showCreateModal: true })}
          timesheets={timesheets}
          toggleAmendedTimesheet={() => setInputs({ ...inputs, amendedTimesheet: !inputs.amendedTimesheet })}
          toggleTimesheet={() =>
            setInputs({ ...inputs, activeTimesheet: !inputs.activeTimesheet, amendedTimesheet: false })
          }
          value={filtersInputs.blockIds}
        />
      )}
      {blocksPage === 2 && (
        <BlockTable
          activeWeek={activeWeek}
          addShift={addShift}
          deselectAllShifts={deselectAllShifts}
          inputs={inputs}
          isShiftSelected={isShiftSelected}
          publishBlock={publishBlock}
          saveChanges={saveChanges}
          selectAllShifts={selectAllShifts}
          selectWeeklyShifts={selectWeeklyShifts}
          selectedShifts={selectedShifts}
          setActiveWeek={setActiveWeek}
          toggleShiftSelection={toggleShiftSelection}
        />
      )}
      <ActionBarComponent
        addShift={addShift}
        closeHandler={() => {
          deselectAllShifts()
          setInputs({ ...inputs, selectedBlock: false })
        }}
        deleteBlock={(row) => setBlockToDelete(row)}
        deleteShifts={deleteShifts}
        editShift={() => setInputs({ ...inputs, editShift: true })}
        inputs={inputs}
        open={actionBarVisible}
        selectedShiftId={selectedShiftId}
        setRecallBlockFromAgencyModal={setRecallBlockFromAgencyModal}
        setSendBlockToAgencyModal={setSendBlockToAgencyModal}
        viewBlock={(e) => {
          toggleActionBar()
          createBlockFunction(e)
        }}
      />
      {/* Modals ************************* */}
      {blocksPage === 1 && (
        <>
          <CreateBlockModal
            details={details}
            handleClose={() => setInputs({ ...inputs, showCreateModal: false })}
            inputs={inputs}
            refetch={(e) => {
              createBlockFunction(e)
              refetch()
            }}
          />

          <ViewBlockModal
            block={inputs.showBlockModal}
            canCancelBooking={canCancelBooking}
            canManageProposals={canManageProposals}
            canShortlistProposals={canShortlistProposals}
            details={details}
            editBlock={(e) => createBlockFunction(e)}
            handleClose={() => setInputs({ ...inputs, showBlockModal: false })}
            inputs={inputs}
            modOrganisation={modOrganisation}
            org={organisation.id}
            refetch={refetch}
            timesheets={timesheets}
            usesDeContracts={usesDeContracts}
          />
          <DeleteBlockModal
            blockToDelete={blockToDelete}
            handleCloseConfirm={() => {
              setInputs({ ...inputs, selectedBlock: false })
              toggleActionBar()
              setBlockToDelete(null)
            }}
            refetch={refetch}
          />
          <SendBlockToAgencyModal
            block={sendBlockToAgencyOpen}
            handleClose={() => {
              setSendBlockToAgencyModal(false)
              toggleActionBar(false)
            }}
            openModal={!!sendBlockToAgencyOpen}
            refetch={refetch}
            setSelectedBlock={(e) => setInputs({ ...inputs, selectedBlock: e })}
          />
          <RecallFromAgencyModal
            block={recallBlockFromAgencyOpen}
            handleClose={() => {
              setRecallBlockFromAgencyModal(false)
              toggleActionBar(false)
            }}
            openModal={!!recallBlockFromAgencyOpen}
            refetch={refetch}
            setSelectedBlock={() => setInputs({ ...inputs, selectedBlock: false })}
          />
        </>
      )}
      {(shiftModalDate || (shiftDetails && inputs.editShift)) && (
        <ShiftsModal
          calendarDetails={calendarDetails}
          details={details}
          edit={inputs.editShift}
          handleClose={() => {
            setInputs({ ...inputs, editShift: false, shiftDetails: null })
            setShiftModalDate()
            deselectAllShifts()
          }}
          modOrganisation={modOrganisation}
          refetch={(e) => setInputs({ ...inputs, calendarDetails: e, editShift: false })}
          shiftDetails={shiftDetails}
          shiftModalDate={shiftModalDate}
        />
      )}
    </>
  )
}

export default BlockBookings

const BlockHeader = styled.div`
  background: #002033;
  height: ${(props) => (props.open ? '430px' : '280px')};
  margin: 0;
  padding: 20px;
  transition: 250ms ease-in-out;
  @media (max-width: 1605px) {
    height: ${(props) => (props.open ? '530px' : '280px')};
  }
`

const FilterContainer = styled.div`
  max-height: ${({ open }) => (open ? '200px' : 0)};
  opacity: ${({ open }) => (open ? '1' : '0')};
  display: ${({ open }) => !open && 'none'};
  transition: 250ms ease-in-out;
  * {
    pointer-events: ${({ open }) => (open ? 'initial' : 'none')};
  }
`
