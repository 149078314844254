import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class NavigationController extends Controller {
  static get targets() {
    return [
      'navigationArea',
      'navLinkToggle',
      // shifts
      'shiftDropdown',
      'shiftActive',
      'shiftListActive',
      'shiftCalendarActive',
      // blocks
      'blocksActive',
      // departments
      'departmentsActive',
      // staff
      'staffDropdown',
      'staffActive',
      'vmsStaffActive',
      'vmsUsersActive',
      'allStaffActive',
      'externalStaffActive',
      'activiationStaffActive',
      // accounts
      'allAccountsActive',
      'allAccountsBlock',
      'hubAccountsActive',
      'superAdminsActive',
      // agencies
      'agenciesDropdown',
      'allAgenciesActive',
      'viewAgenciesActive',
      'locumsAgenciesActive',
      'agencyUsersActive',
      'shiftsAgenciesActive',
      'tiersAgenciesActive',
      // itemised
      'allItemisedActive',
      'itemisedDropdown',
      'itemisedRatesActive',
      'itemisedReceiptsActive',
      // gateway
      'allUploadGatewayActive',
      // payments
      'allPaymentsActive',
      // organisation settings
      'orgSettingsDropdown',
      'allOrganisationActive',
      'sitesOrganisationActive',
      'divisionsOrganisationActive',
      'gradeOrganisationActive',
      'staffOrganisationActive',
      'requestsOrganisationActive',
      'cancellationsOrganisationActive',
      'rateAuthsOrganisationActive',
      'costCentresOrganisationActive',
      'referenceCodesOrganisationActive',
      'externalUnitsOrganisationActive',
      // organisations
      'allOrganisationsActive',
      // users
      'allUsersActive',
      'collabBankActive',
      // patchworksystem
      'allPatchworkSystemActive',
      'patchworkSystemDropdown',
      'filterGradeActive',
      'skillActive',
      'paperTrailActive',
      'regBodyActive',
      'staffGroupActive',
      'skillSetsActive',
      'specialitiesActive',
      //
      'dropdown',
      'currentOrganisation',
    ]
  }

  connect() {
    if (!this.hasDropdownTarget) {
      return
    }

    const query = `query GetOrganisationsHelper($inCollaborativeBank: Boolean) {
        organisations(inCollaborativeBank: $inCollaborativeBank) {
          id
          logoUrl
          name
        }
      }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        // Get the dropdown element
        const dropdown = this.dropdownTarget

        // Clear existing options
        dropdown.innerHTML = ''

        // Sort organizations by name in ascending order
        const sortedOrganizations =
          data?.data?.organisations.sort((a, b) => {
            return a.name.localeCompare(b.name)
          }) || []
        // Populate the dropdown with sorted organization options
        sortedOrganizations.forEach((org) => {
          const option = document.createElement('option')
          option.value = org.id
          option.text = org.name
          dropdown.appendChild(option)
        })

        // Set the default selected option to the current organization, if available
        const currentOrgId = this.currentOrganisationTarget.value
        const defaultOption = dropdown.querySelector(`option[value="${currentOrgId}"]`)
        if (defaultOption) {
          defaultOption.selected = true
        }
      })

    // shifts
    if (window.location.pathname == '/vms/shifts') {
      this.shiftActiveTarget.classList.add('navigation__navbar-item--active')
      // departments
    } else if (window.location.pathname == '/shifts') {
      this.shiftActiveTarget.classList.add('navigation__navbar-item--active')
      this.shiftDropdownTarget.classList.add('d-block')
      this.shiftListActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname === '/shifts_calendar') {
      this.shiftActiveTarget.classList.add('navigation__navbar-item--active')
      this.shiftDropdownTarget.classList.add('d-block')
      this.shiftCalendarActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/blocks') {
      this.blocksActiveTarget.classList.add('navigation__navbar-item--active')
      // view all staff
    } else if (window.location.pathname == '/departments') {
      this.departmentsActiveTarget.classList.add('navigation__navbar-item--active')
      // view all staff
    } else if (window.location.pathname == '/vms/agency_workers') {
      this.vmsStaffActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/vms/agency_users') {
      this.vmsUsersActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/workers') {
      this.staffActiveTarget.classList.add('navigation__navbar-item--active')
      this.staffDropdownTarget.classList.add('d-block')
      this.allStaffActiveTarget.classList.add('navigation__accordion-item--active')
      // external recruitment
    } else if (window.location.pathname == '/external_applications') {
      this.staffActiveTarget.classList.add('navigation__navbar-item--active')
      this.staffDropdownTarget.classList.add('d-block')
      this.externalStaffActiveTarget.classList.add('navigation__accordion-item--active')
      // activation requests
    } else if (window.location.pathname == '/internal_applications') {
      this.staffActiveTarget.classList.add('navigation__navbar-item--active')
      this.staffDropdownTarget.classList.add('d-block')
      this.activiationStaffActiveTarget.classList.add('navigation__accordion-item--active')
      // accounts
    } else if (window.location.pathname == '/accounts') {
      this.allAccountsBlockTarget.classList.add('d-block')
      this.allAccountsActiveTarget.classList.add('navigation__navbar-item--active')
      this.allAccountsActiveTarget.classList.add('navigation__navbar-item--active-open')
      this.hubAccountsActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/agency_users') {
      this.allAgenciesActiveTarget.classList.add('navigation__navbar-item--active')
      this.agenciesDropdownTarget.classList.add('d-block')
      this.agencyUsersActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/super_admins') {
      this.allAccountsActiveTarget.classList.add('navigation__navbar-item--active')
      this.allAccountsBlockTarget.classList.add('d-block')
      this.superAdminsActiveTarget.classList.add('navigation__accordion-item--active')
      // view agencies
    } else if (window.location.pathname == '/agencies') {
      this.allAgenciesActiveTarget.classList.add('navigation__navbar-item--active')
      this.agenciesDropdownTarget.classList.add('d-block')
      this.viewAgenciesActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/agency_tiers') {
      this.allAgenciesActiveTarget.classList.add('navigation__navbar-item--active')
      this.agenciesDropdownTarget.classList.add('d-block')
      this.tiersAgenciesActiveTarget.classList.add('navigation__accordion-item--active')
      // View locums
    } else if (window.location.pathname == '/agency_locums') {
      this.allAgenciesActiveTarget.classList.add('navigation__navbar-item--active')
      this.agenciesDropdownTarget.classList.add('d-block')
      this.locumsAgenciesActiveTarget.classList.add('navigation__accordion-item--active')
      // Agency Shifts
    } else if ((window.location.pathname == '/shifts') + (window.location.search == '?view_type=agency')) {
      this.allAgenciesActiveTarget.classList.add('navigation__navbar-item--active')
      this.agenciesDropdownTarget.classList.add('d-block')
      this.shiftsAgenciesActiveTarget.classList.add('navigation__accordion-item--active')
      // external recruitment
    } else if (window.location.pathname == '/external_applications') {
      this.staffActiveTarget.classList.add('navigation__navbar-item--active')
      this.staffDropdownTarget.classList.add('d-block')
      this.externalStaffActiveTarget.classList.add('navigation__accordion-item--active')
      // itemised
    } else if (window.location.pathname == '/itemised_rates') {
      this.allItemisedActiveTarget.classList.add('navigation__navbar-item--active')
      this.itemisedDropdownTarget.classList.add('d-block')
      this.itemisedRatesActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/itemised_receipts') {
      this.allItemisedActiveTarget.classList.add('navigation__navbar-item--active')
      this.itemisedDropdownTarget.classList.add('d-block')
      this.itemisedReceiptsActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname.includes('/upload_gateway')) {
      this.allUploadGatewayActiveTarget.classList.add('navigation__navbar-item--active')

      // batches
    } else if (window.location.pathname.includes('/batches')) {
      this.allPaymentsActiveTarget.classList.add('navigation__navbar-item--active')
      // payments
    } else if (window.location.pathname.includes('/payments/dashboard')) {
      this.allPaymentsActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname.includes('/itemised_rates')) {
      this.itemisedRatesTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/divisions') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.divisionsOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/sites') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.sitesOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
      // view grades
    } else if (window.location.pathname == '/grades') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.gradeOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
      // staff groups
    } else if (window.location.pathname == '/organisation_staff_groups') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.staffOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/reason_for_requests') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.requestsOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/reason_for_requests') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.requestsOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/reason_for_cancellations') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.cancellationsOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/authorisation_levels') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.rateAuthsOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/cost_centres') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.costCentresOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/reference_codes') {
      this.allOrganisationActiveTarget.classList.add('navigation__navbar-item--active')
      this.orgSettingsDropdownTarget.classList.add('d-block')
      this.referenceCodesOrganisationActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/organisations') {
      this.allOrganisationsActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/users') {
      this.allUsersActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/collaborative_banks') {
      this.collabBankActiveTarget.classList.add('navigation__navbar-item--active')
    } else if (window.location.pathname == '/regulation_bodies') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.regBodyActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/staff_groups') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.staffGroupActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/skills') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.skillSetsActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/filter_grades') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.filterGradeActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/paper_trail_admin') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.paperTrailActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/skills') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.skillsActiveTarget.classList.add('navigation__accordion-item--active')
    } else if (window.location.pathname == '/specialities') {
      this.allPatchworkSystemActiveTarget.classList.add('navigation__navbar-item--active')
      this.patchworkSystemDropdownTarget.classList.add('d-block')
      this.specialitiesActiveTarget.classList.add('navigation__accordion-item--active')
    }
  }

  // Add the onchange handler for the dropdown
  handleOrgChange(event) {
    const selectedOrgId = event.target.value

    console.log('Selected Org ID', selectedOrgId)

    // Add your logic here to handle the selected organization change
    // You can use the selectedOrgId to identify the selected organization

    const query = `
          mutation ChangeOrganisation($organisationId: Int!) {
        changeOrganisation(organisationId: $organisationId) {
          success
          errors {
            attribute
            message
          }
        }
      }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { organisationId: Number(selectedOrgId) },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.data?.changeOrganisation?.errors.length) {
          alert('There was an error!')
          return
        }

        // Reload the page
        window.location.reload()
      })
  }

  toggle(event) {
    const navTitleLink = event.target
    const navContainer = navTitleLink.parentNode
    const navLinkSection = navContainer.getElementsByTagName('ul')[0]
    if (navLinkSection) {
      navLinkSection.classList.toggle('d-block')
      navTitleLink.classList.toggle('navigation__navbar-item--active')
      navTitleLink.classList.toggle('navigation__navbar-item--active-open')
    }
  }
}

application.register('navigation', NavigationController)

// navigation__navbar-item--active
