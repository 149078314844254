import { Controller } from 'stimulus'

export default class FeatureFlagsController extends Controller {
  static get targets() {
    return ['featureFlag'];
  }

  connect() {
    console.log('FeatureFlagsController');
  }

  toggled(e) {
    document.getElementById('flag_name').value = e.target.id;
    var checked = e.target.checked ? 'true' : 'false';
    document.getElementById('flag_is_set').value = checked;

    document.getElementById('feature_flag_update').submit();
  }

}
import { application } from '../../frontend/controllers/index'

application.register("feature_flags", FeatureFlagsController)
